<template>
    <div class="yz-ab-wap">
        <erji-carousel></erji-carousel>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 合作益处 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                    <!-- 全球互联网教育平台运营商，致力于构建终身教育生态系统 -->
                </p>
            </div>
        </div>
        <div class="ab-con">
            <div class="ab-img"><img src="../../assets/images/c1.png"></div>
            <div class="ab-item">
               <div class="yz-benefits-co">
                    <h2>合作给学生带来的益处</h2>
                    <p>提高学员的专业就业能力，帮助学员进入国家重点发展产业，为职业发展奠定基础；</p>
                    <p>能力评测，精确匹配岗位，提供个性化学习及就业推荐，为学员个体发展提供科学指导；</p>
                    <p>创业指导及实践，为学生创业提供真实商业场景及创业支持。</p>
               </div>
               <div class="yz-benefits-co">
                    <h2>合作给学校带来的益处</h2>
                    <p>校企合作、产教融合符合国家职业教育改革方向，探索新型行业应用型人才培养之路；</p>
                    <p>提高学科建设能力，全方位解决专业能力不足、教学资源缺乏，专业人才与企业需求脱钩等问题；</p>
                    <p>提高学校专业影响力，扩大办学规模。</p>
               </div>
               <div class="yz-benefits-co">
                    <h2>合作的社会价值</h2>
                    <p>助力产业发展，为新型产业提供人才支撑；</p>
                    <p>产教融合，探索新业态及新经济增长方式；</p>
                    <p>科技与教育深度融合，探索新技术条件下的教学新模式。</p>
               </div>
            </div>
        </div>
         <div style="clear:both"></div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
   .yz-ab-wap{
       width: 100%;
       height: auto;
       .ab-text{
           margin-top:40px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
        .ab-con{
            width: 1200px;
            margin:0 auto;
            clear: both;
            overflow: hidden;
            padding:50px 0px 50px 0px;
               .ab-img{
                   width:40%;
                   height: 400px;
                   float: left;
                     img{
                         width:100%;
                         height: 100%;
                     }
               }
               .ab-item{
                    width:58%;
                    height: 300px;
                    float: right;
                   .yz-benefits-co{
                       h2{
                           font-size: 18px;
                           color: #0184d2;
                           font-weight: bold;
                           text-align: left;
                           margin:20px 0px;
                       }
                        p{
                            text-align: left;
                            color:#000;
                            line-height: 25px;
                            font-size: 16px;
                        }
                   }

               }
           }
   }
</style>
